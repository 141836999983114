/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Seth's Portfolio",
  description:
    "Experienced web developer proficient in multiple backend technologies and server administration",
  og: {
    title: "Seth's Portfolio",
    type: "website",
    url: "http://sethcode.com/",
  },
};

//Home Page
const greeting = {
  title: "Seth Díaz",
  logo_name: "Seth Díaz",
  nickname: "Sethndd",
  subTitle:
    "Experienced web developer proficient in multiple backend technologies and server administration. I am a passionate learner who's always willing to learn and work across technologies and domains.",
  //resume is at public folder
  resumeLink: "/pdf/SethDiazCv.pdf",
  portfolio_repository: "https://github.com/Sethndd/masterPortfolio",
  githubProfile: "https://github.com/Sethndd",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/seth-diaz/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Github",
    link: "https://github.com/Sethndd",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Gmail",
    link: "mailto:seth261099@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/sethndd/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Back End Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Proficient in building responsive website backends using Spring Boot and NestJS, focusing on performance and security.",
        "⚡ Skilled in Java, Kotlin, and TypeScript, ensuring versatility in development environments.",
        "⚡ Experienced in developing RESTful APIs, GraphQL APIs, and implementing Websockets for real-time communication",
        "⚡ Capable of creating and managing databases with MySQL, PostgreSQL, and MS SQL Server, ensuring efficient data storage and retrieval.",
      ],
      softwareSkills: [
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#0095D5",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "devicon:java",
          style: {
            color: "#007396",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:spring",
          style: {
            color: "#6DB33F",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "logos:nodejs-icon",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NestJS",
          fontAwesomeClassname: "simple-icons:nestjs",
          style: {
            color: "#E0234E",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MS SQL Server",
          fontAwesomeClassname: "simple-icons:microsoftsqlserver",
          style: {
            color: "#CC2927",
          },
        },
      ],
    },
    {
      title: "Front End Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Proficient in building responsive website front-ends using Angular and React, ensuring optimal user experiences across devices.",
        "⚡ Skilled in utilizing state management libraries such as Redux, and NgRx to efficiently manage application state and data flow.",
        "⚡ Experienced in consuming RESTful APIs, GraphQL APIs, and integrating Websockets for real-time data updates.",
        "⚡ Well-versed in UI development with expertise in Material UI, Bootstrap, Tailwind CSS, and other UI libraries, enhancing visual appeal and usability of applications.",
      ],
      softwareSkills: [
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "logos:nodejs-icon",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#007ACC",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
      ],
    },
    {
      title: "Cloud",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Proficient in working with various operating systems including Debian, Ubuntu, CentOS, and Windows Server, ensuring versatility in cloud environments",
        "⚡ Experienced in building and managing cloud platforms such as Google Cloud Platform (GCP), Amazon Web Services (AWS), and Microsoft Azure, optimizing scalability, performance, and cost-efficiency.",
        "⚡ Skilled in deploying and maintaining Docker containers, Kubernetes clusters, and serverless functions, leveraging containerization and orchestration technologies for seamless application deployment.",
        "⚡ Capable of setting up continuous integration and continuous deployment (CI/CD) pipelines using Jenkins, GitLab CI, and GitHub Actions, automating software delivery processes for faster iteration and deployment.",
        "⚡ Proficient in managing and monitoring cloud resources using Grafana, Prometheus, and the ELK stack, ensuring high availability, performance, and reliability of cloud-based applications.",
        "⚡ Experienced in configuring and managing websites and APIs using Cloudflare infrastructure, optimizing security, performance, and reliability.",
      ],
      softwareSkills: [
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Debian",
          fontAwesomeClassname: "simple-icons:debian",
          style: {
            color: "#A80030",
          },
        },
        {
          skillName: "Ubuntu",
          fontAwesomeClassname: "simple-icons:ubuntu",
          style: {
            color: "#E95420",
          },
        },
        {
          skillName: "Windows Server",
          fontAwesomeClassname: "simple-icons:windows",
          style: {
            color: "#0078D6",
          },
        },
        {
          skillName: "Cloudflare",
          fontAwesomeClassname: "simple-icons:cloudflare",
          style: {
            color: "#F38020",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Universidad Veracruzana",
      subtitle: "Software Engineering",
      logo_path: "lis-logo.png",
      alt_name: "Licenciatura en Ingeniería de Software",
      duration: "",
      descriptions: [
        "⚡ Completed a comprehensive curriculum focusing on software development principles, methodologies, and best practices.",
        "⚡ Acquired a strong foundation in computer science fundamentals, including data structures, algorithms, and programming languages.",
        "⚡ Developed expertise in software design, development, testing, and deployment processes",
        "⚡ Participated in collaborative team projects, enhancing communication and teamwork skills essential for software development roles.",
      ],
      website_link: "https://www.uv.mx/",
    },
    {
      title: "Scrum.org",
      subtitle: "Scrum Fundamentals",
      logo_path: "scrum-logo.png",
      alt_name: "Scrum Fundamentals",
      duration: "",
      descriptions: [
        "⚡ Completed the Scrum Fundamentals course, gaining a solid understanding of Scrum principles and practices.",
        "⚡ Acquired knowledge of the Scrum framework, roles, events, and artifacts, and how they work together to deliver value.",
        "⚡ Developed skills in applying Scrum practices to software development projects, improving productivity and quality.",
        "⚡ Passed the Scrum.org Professional Scrum Master I assessment, demonstrating proficiency in Scrum principles and practices.",
      ],
      certificate_link: "https://www.scrum.org/",
    },
  ],
};

const certifications = {
  certifications: [
    // TODO: Add certifications
    // {
    //   title: "Machine Learning",
    //   subtitle: "- Andrew Ng",
    //   logo_path: "stanford_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
    //   alt_name: "Stanford University",
    //   color_code: "#8C151599",
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Quality & Knowledge On IT Services",
          company_url: "https://qacg.com/",
          logo_path: "qk-Icon.png",
          duration: "JJan 2022 - Present",
          location: "Mexico City, CDMX, MX",
          description:
            "I was instrumental in developing and maintaining robust backend systems using Spring Boot, Java, and Kotlin. I implemented essential features such as batch processing, asynchronous processing, security protocols, and WebSocket communication, thereby enhancing system efficiency and reliability. Collaborating closely with cross-functional teams, I contributed to the design and implementation of scalable solutions, ensuring seamless integration across the platform. Additionally, I designed and implemented dynamic front-end interfaces using the Angular framework. Through active participation in code reviews, debugging, and troubleshooting, I ensured the delivery of high-quality code and promptly resolved technical issues. My strong problem-solving skills and adaptability were evident in navigating fast-paced Agile development environments effectively.",
          color: "#000000",
        },
        {
          title: "Web Developer",
          company: "OV Film Cancún",
          company_url: "http://www.ovfilm.com/",
          logo_path: "ov-logo.png",
          duration: "Jun 2021 — Jan 2022",
          location: "Canún, QR, MX",
          description:
            "I was responsible for managing and maintaining the company's website infrastructure to ensure its reliability and optimal performance. I provided technical support and troubleshooting assistance to the web development team, resolving issues promptly to minimize downtime. I also collaborated with the design team to implement new features and enhancements to the website, ensuring a seamless user experience for visitors.",
          color: "#0879bf",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description: "blah blah blah",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile-pic.png",
    description:
      "You can find me on LinkedIn, GitHub, Instagram, or send me an email. I'll try to get back to you as soon as I can :)",
  },
  // blogSection: {
  //   title: "Blogs",
  //   subtitle:
  //     "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
  //   link: "https://blogs.ashutoshhathidara.com/",
  //   avatar_image_path: "blogs_image.svg",
  // },
  // addressSection: {
  //   title: "Address",
  //   subtitle: "Saratoga Ave, San Jose, CA, USA 95129",
  //   locality: "Xalapa",
  //   country: "MX",
  //   region: "Veracruz",
  //   postalCode: "91000",
  //   streetAddress: "Saratoga Avenue",
  //   avatar_image_path: "address_image.svg",
  //   location_map_link: "https://www.google.com/maps/place/Xalapa-Enr%C3%ADquez,+Ver./",
  // },
  // phoneSection: {
  //   title: "",
  //   subtitle: "",
  // },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
